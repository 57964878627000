<script>
// import HelloWorld from "./components/HelloWorld.vue";
// import TheWelcome from "./components/TheWelcome.vue";
import json from "./data.json";
export default {
  data() {
    return {
      myJson: json,
    };
  },
};
</script>

<template>
  <header>
    <h1>NUNAVUT WEBCAMS</h1>
    <a class="footer" href="http://visitcanada.live">visitcanada.live</a>
  </header>
  <table>
    <div v-for="location in myJson" :key="location.id">
      <h2>{{ location.title }}</h2>
      <tbody>
        <td v-for="y in location.cameras" :key="y.cameras">
          <a v-bind:href="y.thumbnail">
            <img v-bind:src="y.thumbnail" width="340" />
          </a>
          <h3>{{ y.name }}</h3>
        </td>
      </tbody>
    </div>
  </table>
  <footer>
    <a id="footer" href="http://visitcanada.live">visitcanada.live</a>
    <aside>Copyright 2022</aside>
  </footer>
</template>


<style>
/* h1,
h2,
h3,
figcaption {
  font-family: "Courier New", Courier, monospace;
} */

a {
  text-decoration: none;
  color: black;
}

h1 {
  text-align: center;
  font-size: 60px;
}

h2 {
  text-transform: uppercase;
  display: inline;
}

header {
  margin-bottom: 20px;
}

header,
footer {
  align-items: center;
  text-align: center;
  background-color: #b3b9af;
  padding: 30px;
  border-radius: 25px;
}

#footer {
  font-size: 24px;
  font-weight: bold;
}

.footer {
  /* align-items: center; */
  align-self: right;
  font-size: 24px;
  font-weight: bold;
  text-align: center;
}

aside {
  text-align: right;
}

body {
  background-color: #daddd8;
}

@media only screen and (max-width: 1400px) {
  table {
    max-width: 1200px;
  }

  td:nth-child(3) {
    display: block;
    /* background-color: yellow; */
  }

  tr {
    float: left;
  }

  td {
    float: left;
    min-width: 100px;
  }
}

@media screen and (max-width: 500px) {
  table {
    display: block;
  }
  td {
    display: block;
  }
}
</style>

